import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";
import { first } from "lodash";

export type MapViewType =
  | "list"
  | "live"
  | "timeline"
  | "playback"
  | "new"
  | "edit"
  | "multi-live"
  | "multi-timeline"
  | "multi-playback";

export type Tab = "vehicles" | "drivers" | "areas";

export const mapTabs: { [key: string]: Tab } = {
  VEHICLES: "vehicles",
  DRIVERS: "drivers",
  AREAS: "areas",
};

export interface MapState {
  tab: Tab;
  view: MapViewType;
  ids?: string[];
  timelineDates?: [Dayjs, Dayjs];
  timelineEventId?: string;
  time?: Dayjs;
  isMultiView?: boolean;
}

const defaultMapState = {
  tab: "vehicles" as Tab,
  view: "list" as MapViewType,
};

const mapStateAtom = atom<MapState>(defaultMapState);
mapStateAtom.debugLabel = "mapState";

const initializingMapViewAtom = atom<boolean>(true);
initializingMapViewAtom.debugLabel = "initializingMapView";

/*
 * Derived atoms to prevent renders
 * */

const mapTabAtom = atom((get) => {
  return get(mapStateAtom).tab;
});
mapTabAtom.debugPrivate = true;
const mapViewAtom = atom<MapViewType>((get) => {
  return get(mapStateAtom).view;
});
mapViewAtom.debugPrivate = true;

const objectIdsAtom = atom((get) => {
  return get(mapStateAtom).ids;
});
objectIdsAtom.debugPrivate = true;

const objectIdAtom = atom<string>((get) => {
  const isMultiView = get(isMultiViewAtom);

  if (isMultiView) {
    return "";
  }

  return first(get(mapStateAtom).ids) as string;
});
objectIdAtom.debugPrivate = true;

const timelineDatesAtom = atom<[Dayjs, Dayjs]>((get) => {
  return get(mapStateAtom).timelineDates as [Dayjs, Dayjs];
});
timelineDatesAtom.debugPrivate = true;

const isMultiViewAtom = atom((get) => {
  return get(mapStateAtom).isMultiView;
});
isMultiViewAtom.debugPrivate = true;

const timelineTimeAtom = atom<Dayjs | null>((get) => {
  const time = get(mapStateAtom).time as Dayjs;

  return time ? dayjs.tz(dayjs(time)) : null;
});
timelineTimeAtom.debugPrivate = true;

const timelineEventIdAtom = atom((get) => {
  return get(mapStateAtom).timelineEventId;
});
timelineEventIdAtom.debugPrivate = true;

export {
  mapStateAtom,
  mapViewAtom,
  mapTabAtom,
  objectIdsAtom,
  timelineDatesAtom,
  isMultiViewAtom,
  timelineTimeAtom,
  timelineEventIdAtom,
  objectIdAtom,
  initializingMapViewAtom,
};
