import { t } from "@lingui/macro";
import { message } from "antd";
import { atom, getDefaultStore, useSetAtom } from "jotai";
import { atomWithStorage, selectAtom } from "jotai/utils";
import { first, isEqual } from "lodash";
import omit from "lodash/omit";

import { appConfigAtom } from "atoms/configurations";

import { MapLayers } from "components/deck-gl/instance";
import mapSettings from "components/map/controls/map-settings";
import { DEFAULT_VERTICAL_SPLIT_VIEW } from "components/map/hooks/layout";
import { isUndefinedOrNull } from "components/map/utils";

export const mapSettingsKey = "navirec-map-layer-settings-v1";

export type MapControlsMenu =
  | "map-layer"
  | "map-settings"
  | "data-layers"
  | "route-planner"
  | "send-message"
  | "search-vehicles"
  | undefined;

export interface MapSettings {
  mapLayer?: {
    provider: "";
    layer: "";
  };
  provider: MapLayers;
  showTrafficLayer: boolean;
  showAreas: boolean;
  showPoints: boolean;
  vehicleNames: boolean;
  smallerVehicleIcons: boolean;
  smallerPointsIcons: boolean;
  showStats?: boolean;
  groupVehicles: boolean;
  showFullDay: boolean;
  chartAndMapViewHeight?: number[];
  listAndMapViewWidth: number[];
  showVehicleDetails: boolean;
  showDriverDetails: boolean;
  showTimeInPlaybackRangePicker: boolean;
  mapControlMenu: MapControlsMenu;
  showRecentTrailingLine?: boolean;
  //playback
  showParkingTimes: boolean;
  colorTrips: boolean;
  showFuture?: boolean;
  zoomToCurrentTrip?: boolean;
  showEventsIcon?: boolean;
  showStopsIcon?: boolean;
  showTrailingLine?: boolean;
  iconAnimation?: boolean;
  showDirections?: boolean;
  showActivities?: boolean;
  playbackDuration: number;
  showStreamingStats?: boolean;
  showAddressSearchBackends?: boolean;
}

const mapSettingsDefault = {
  provider: "google-road" as MapLayers,
  showTrafficLayer: false,
  showAreas: true,
  showPoints: true,
  vehicleNames: true,
  smallerVehicleIcons: true,
  showStats: false,
  smallerPointsIcons: true,
  groupVehicles: true,
  showFullDay: false,
  showParkingTimes: true,
  colorTrips: true,
  chartAndMapViewHeight: [70, 30],
  listAndMapViewWidth: DEFAULT_VERTICAL_SPLIT_VIEW,
  showVehicleDetails: false,
  showDriverDetails: false,
  showTimeInPlaybackRangePicker: false,
  mapControlMenu: undefined,
  showFuture: false,
  zoomToCurrentTrip: true,
  showEventsIcon: true,
  showStopsIcon: true,
  showRecentTrailingLine: true,
  showTrailingLine: false,
  iconAnimation: true,
  showDirections: true,
  showActivities: true,
  playbackDuration: 5,
  showStreamingStats: false,
  showAddressSearchBackends: false,
};

const mapSettingsAtom = atomWithStorage<MapSettings>(mapSettingsKey, mapSettingsDefault, undefined, {
  getOnInit: true,
});
mapSettingsAtom.debugLabel = "mapSettings";

const store = getDefaultStore();

const getMapLayerProvider = (provider: string) => {
  const config = store.get(appConfigAtom);
  const layers = config?.map_layers;

  if (!layers?.includes(provider)) {
    return first(layers) as MapLayers;
  }

  return provider;
};

export const providerSelector = selectAtom(mapSettingsAtom, (settings) => {
  return getMapLayerProvider(settings.provider);
});
export const showTrafficLayerSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showTrafficLayer) ? mapSettingsDefault.showTrafficLayer : settings.showTrafficLayer
);
export const vehicleNamesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.vehicleNames) ? mapSettingsDefault.vehicleNames : settings.vehicleNames
);
export const smallerVehicleIconsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.smallerVehicleIcons)
    ? mapSettingsDefault.smallerVehicleIcons
    : settings.smallerVehicleIcons
);
export const smallerPointsIconsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.smallerPointsIcons) ? mapSettingsDefault.smallerPointsIcons : settings.smallerPointsIcons
);
export const showStatsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showStats) ? mapSettingsDefault.showStats : settings.showStats
);
export const groupVehiclesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.groupVehicles) ? mapSettingsDefault.groupVehicles : settings.groupVehicles
);
export const showFullDaySelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showFullDay) ? mapSettingsDefault.showFullDay : settings.showFullDay
);
export const chartAndMapViewHeightSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.chartAndMapViewHeight)
    ? mapSettingsDefault.chartAndMapViewHeight
    : settings.chartAndMapViewHeight
);
export const listAndMapViewWidthSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.listAndMapViewWidth)
    ? mapSettingsDefault.listAndMapViewWidth
    : settings.listAndMapViewWidth
);
export const showVehicleDetailsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showVehicleDetails) ? mapSettingsDefault.showVehicleDetails : settings.showVehicleDetails
);
export const showDriverDetailsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showDriverDetails) ? mapSettingsDefault.showDriverDetails : settings.showDriverDetails
);
export const mapControlMenuSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.mapControlMenu) ? mapSettingsDefault.mapControlMenu : settings.mapControlMenu
);
export const showRecentTrailingLineSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showRecentTrailingLine)
    ? settings.showRecentTrailingLine
    : mapSettingsDefault.showRecentTrailingLine
);
export const showParkingTimesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showParkingTimes) ? mapSettingsDefault.showParkingTimes : settings.showParkingTimes
);
export const colorTripsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.colorTrips) ? mapSettingsDefault.colorTrips : settings.colorTrips
);
export const playbackDurationSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.playbackDuration) ? mapSettingsDefault.playbackDuration : settings.playbackDuration
);
export const showStreamingStatsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showStreamingStats) ? mapSettingsDefault.showStreamingStats : settings.showStreamingStats
);
export const showFutureSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showFuture) ? mapSettingsDefault.showFuture : settings.showFuture
);
export const zoomToCurrentTripSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.zoomToCurrentTrip) ? mapSettingsDefault.zoomToCurrentTrip : settings.zoomToCurrentTrip
);
export const showEventsIconSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showEventsIcon) ? mapSettingsDefault.showEventsIcon : settings.showEventsIcon
);
export const showStopsIconSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showStopsIcon) ? mapSettingsDefault.showStopsIcon : settings.showStopsIcon
);
export const showTrailingLineSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showTrailingLine) ? mapSettingsDefault.showTrailingLine : settings.showTrailingLine
);
export const iconAnimationSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.iconAnimation) ? mapSettingsDefault.iconAnimation : settings.iconAnimation
);
export const showDirectionsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showDirections) ? mapSettingsDefault.showDirections : settings.showDirections
);
export const showActivitiesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showActivities) ? mapSettingsDefault.showActivities : settings.showActivities
);
export const showAddressSearchBackendsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showAddressSearchBackends)
    ? mapSettingsDefault.showAddressSearchBackends
    : settings.showAddressSearchBackends
);

export const isSettingsUnchangedAtom = atom((get) => {
  const settings = get<MapSettings>(mapSettingsAtom);
  const mapSettings = { ...mapSettingsDefault, ...settings };

  return isEqual(omit(mapSettings, ["mapControlMenu"]), omit(mapSettingsDefault, ["mapControlMenu"]));
});

const ignoreMapClickActionAtom = atom<boolean>(false);
ignoreMapClickActionAtom.debugPrivate = true;

const ignoreMapViewStateChangeAtom = atom<boolean>(false); // preserves map view port even when values changes.
ignoreMapViewStateChangeAtom.debugPrivate = true;

const useMapSettings = () => {
  const setSettings = useSetAtom(mapSettingsAtom);

  const onChangeSettings = (key: string, value: string | boolean | number[] | number | MapControlsMenu) => {
    setSettings((res: MapSettings) => ({
      ...res,
      [key]: value,
    }));
  };

  const onResetSettings = () => {
    setSettings({ ...mapSettingsDefault, mapControlMenu: "map-settings" });
    message.success(t`Default map settings has been applied.`);
  };

  return { onChangeSettings, onResetSettings };
};

export { mapSettingsAtom, useMapSettings, ignoreMapClickActionAtom, ignoreMapViewStateChangeAtom };
