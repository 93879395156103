// source: https://jotai.org/docs/recipes/atom-with-debounce
import { SetStateAction } from "react";

import { atom } from "jotai";
import { createJSONStorage } from "jotai/utils";
import { delay } from "lodash";

const atomWithDebounce = <T>(initialValue: T, delayMilliseconds = 1000, shouldDebounceOnReset = false) => {
  const prevTimeoutAtom = atom<ReturnType<typeof setTimeout> | undefined>(undefined);

  // DO NOT EXPORT currentValueAtom as using this atom to set state can cause inconsistent state between currentValueAtom and debouncedValueAtom
  const _currentValueAtom = atom(initialValue);
  const isDebouncingAtom = atom<boolean>(false);

  const debouncedValueAtom = atom(initialValue, (get, set, update: SetStateAction<T>) => {
    clearTimeout(get(prevTimeoutAtom));

    const prevValue = get(_currentValueAtom);
    const nextValue = typeof update === "function" ? (update as (prev: T) => T)(prevValue) : update;

    const onDebounceStart = () => {
      set(_currentValueAtom, nextValue);
      set(isDebouncingAtom, true);
    };

    const onDebounceEnd = () => {
      set(debouncedValueAtom, nextValue);
      set(isDebouncingAtom, false);
    };

    onDebounceStart();

    if (!shouldDebounceOnReset && nextValue === initialValue) {
      onDebounceEnd();
      return;
    }

    const nextTimeoutId = setTimeout(() => {
      onDebounceEnd();
    }, delayMilliseconds);

    // set previous timeout atom in case it needs to get cleared
    set(prevTimeoutAtom, nextTimeoutId);
  });

  // exported atom setter to clear timeout if needed
  const clearTimeoutAtom = atom(null, (get, set, _arg) => {
    clearTimeout(get(prevTimeoutAtom));
    set(isDebouncingAtom, false);
  });

  return {
    currentValueAtom: atom((get) => get(_currentValueAtom)),
    isDebouncingAtom,
    clearTimeoutAtom,
    debouncedValueAtom,
  };
};

const atomWithThrottle = <T>(initialValue: T, interval = 300) => {
  const baseAtom = atom<T>(initialValue);
  const lastUpdateAtom = atom(0);
  const pendingUpdateAtom = atom<T | null>(null); // To store the pending update

  return atom(
    (get) => get(baseAtom), // Read the raw value
    (get, set, update: T) => {
      const now = Date.now();
      const lastUpdateTime = get(lastUpdateAtom);

      if (now - lastUpdateTime >= interval) {
        // Update the value immediately if the interval has passed
        set(baseAtom, update);
        set(lastUpdateAtom, now);

        // Clear any pending updates since we're processing this one
        set(pendingUpdateAtom, null);
      } else {
        // Store the update to be processed later
        set(pendingUpdateAtom, update);

        // Schedule the update to occur after the interval
        delay(() => {
          const pendingUpdate = get(pendingUpdateAtom);
          if (pendingUpdate !== null) {
            set(baseAtom, pendingUpdate);
            set(lastUpdateAtom, Date.now());
            set(pendingUpdateAtom, null); // Clear the pending update
          }
        }, interval - (now - lastUpdateTime));
      }
    }
  );
};

const sessionStore = createJSONStorage(() => sessionStorage) as any;

export { atomWithDebounce, atomWithThrottle, sessionStore };
