import { LatLng } from "types";

const getCoordinate = (location: any): LatLng | undefined => {
  if (location?.type === "Point" && location?.coordinates) {
    return { lat: location.coordinates[1], lng: location.coordinates[0] };
  }

  return undefined;
};

export { getCoordinate };
